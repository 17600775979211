import { useState } from 'react'
import TagManager from 'react-gtm-module'
import { useEngagement } from './use-engagement'

export type MailchimpApiProps = {
  email: string
  source?: string
  vars?: Record<string, string | number | undefined | null>
  tags?: string[] | Record<string, string>[]
  event?: { name: string; occurred_at?: string }
  audience?: string
}

export const useMailchimp = () => {
  const { setEngagement } = useEngagement()
  const [success, setSuccess] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const signUp = async ({ email, source, vars, tags, audience = 'fbd2c374d9' }: MailchimpApiProps) => {
    setIsReady(false)

    const res = await fetch('/api/mailchimp-user-subscribe', {
      body: JSON.stringify({
        email,
        vars,
        tags,
        audience,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    if (!res.ok) {
      console.error('Newsletter sign-up error')
      setSuccess(false)
      setIsReady(true)
      return { success: false }
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: 'newsletterSignUp',
          newsletterSignUpSource: source,
        },
      })
      setSuccess(true)
      setEngagement({ hasEngaged: true, recentEmail: email })
      setIsReady(true)
      return { success: true }
    }
  }

  const updateUser = async ({ email, vars, tags, event, audience = 'fbd2c374d9' }: MailchimpApiProps) => {
    setIsReady(false)

    const res = await fetch('/api/mailchimp-user-update', {
      body: JSON.stringify({
        email,
        vars,
        tags,
        event,
        audience,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    if (!res.ok) {
      console.error('User update error')
      setSuccess(false)
      setIsReady(true)
      return { success: false }
    } else {
      setSuccess(true)
      setIsReady(true)
      return { success: true }
    }
  }

  const updateTags = async ({ email, tags, audience = 'fbd2c374d9' }: MailchimpApiProps) => {
    setIsReady(false)

    const res = await fetch('/api/mailchimp-tags', {
      body: JSON.stringify({
        email,

        tags,
        audience,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    if (!res.ok) {
      console.error('Tags update error')
      setSuccess(false)
      setIsReady(true)
      return { success: false }
    } else {
      setIsReady(true)
      return { success: true }
    }
  }

  return { success, signUp, updateUser, updateTags, isReady }
}
