import { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import * as UI from '@/ui'
import { cn } from '@/helpers/tailwind'

export type ModalProps = {
  children: React.ReactNode
  show: boolean
  onClose: () => void
  title?: string
  color?: 'light' | 'dark' | 'none'
  disableOutsideClose?: boolean
  container?: boolean
}

const bgColors = {
  none: '',
  light: 'bg-selphGrey-50',
  dark: 'bg-selphGrey-200',
}

export const Modal = ({ title, color, show, onClose, children, disableOutsideClose, container = true }: ModalProps) => {
  const bgColor = bgColors[color || 'light']

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        open={show}
        onClose={disableOutsideClose ? () => null : onClose}
        onKeyDown={(e) => {
          if (disableOutsideClose && e.key === 'Escape') {
            onClose()
          }
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-selphGrey-600 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={cn(bgColor, 'relative transform overflow-visible transition-all rounded-lg shadow-xl', {
                  'px-3 pt-2 sm:pt-4 pb-2 sm:pb-4 text-left sm:my-8 sm:w-full sm:max-w-fit sm:p-4 sm:mx-2': container,
                })}
              >
                <div className="absolute top-0 right-0 pt-2 pr-2 sm:pr-4 sm:pt-4">
                  <button
                    type="button"
                    className={`rounded-full bg-selphGrey-50 bg-opacity-50 text-gray-500 hover:text-hotPink-500  border-2 border-gray-500 hover:border-hotPink-500`}
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-7 w-7" aria-hidden="true" />
                  </button>
                </div>
                <UI.Block>
                  {title && (
                    <DialogTitle as="h3" className="mr-8 text-lg font-medium leading-6 text-selphGrey-800 mb-2">
                      {title}
                    </DialogTitle>
                  )}
                  {children}
                </UI.Block>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.displayName = 'Modal'

export default Modal
