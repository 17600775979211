import { RefObject, useEffect, useRef } from 'react'
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch'
import { Hit } from './hit'
import * as UI from '@/ui'

export type AlgoliaSearchRecord = {
  objectID: string
  name: string
  type: string
  slug: string
  price: number
  description: string
  showInListings: boolean
  featuredImage: {
    src: string
    name: string
  }
  [key: string]: any
}

export const SearchResults = ({
  mobileSearch,
}: {
  mobileSearch?: { searchOpen: boolean; setSearchOpen: (v: boolean) => void }
}) => {
  const { items } = useInfiniteHits<AlgoliaSearchRecord>()
  const { indexUiState, setIndexUiState, status } = useInstantSearch()
  const containerRef = useRef<HTMLDivElement>(null)

  useClickOutside(containerRef, () => {
    // Clear the search query to close the results container
    setIndexUiState((state) => ({ ...state, query: '' }))
  })

  // Don't render if no query, query is too short or status is "loading" or "stalled"
  if (!indexUiState.query || indexUiState.query.length < 2 || status === 'loading' || status === 'stalled') {
    return null
  }

  return (
    <div ref={containerRef} className="md:absolute w-full md:w-xl md:-mt-12 z-20">
      {items.length === 0 ? (
        <div className="absolute w-full md:w-xl -ml-10 md:ml-0 px-4 h-20 flex justify-center items-center md:bg-white border-b md:border border-gray-100  md:border-slate-300 md:rounded-md z-20">
          <UI.Paragraph color="lightGrey">
            Sorry, there are no results for<i>{` "${indexUiState.query}".`}</i>
          </UI.Paragraph>
        </div>
      ) : (
        <div className="flex justify-center items-center md:bg-white md:rounded-md">
          <div className="-ml-10 md:ml-0 ">
            {items.map((hit) => (
              <Hit key={hit.objectID} hit={hit} mobileSearch={mobileSearch} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchResults

function useClickOutside(ref: RefObject<HTMLElement>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      handler()
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}
