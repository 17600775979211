import cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import * as UI from '@/ui'

interface CookieOptions {
  expires: number
}

function startTracking() {
  ReactGA.event('start_tracking')
}

const Tracking = () => {
  const [isVisible, setIsVisible] = useState(false)

  const storeConsentCookie = (value: string, options?: CookieOptions) => {
    setIsVisible(false)
    cookies.set('cookieConsent', value, options)
  }

  // If we get explicit acceptance or rejection, remember it for 'ever'
  const onReject = () => storeConsentCookie('false', { expires: 365 })
  const onAccept = () => {
    storeConsentCookie('true', { expires: 365 })
    startTracking()
  }

  // If the prompt is just dismissed, don't show it again for the session
  // so the user will be asked again next visit
  // TODO: Temporarily removed close button. Either remove completely
  // or restore, depending if we see more acceptance
  // const onDismiss = () => storeConsentCookie(false)

  useEffect(
    () => {
      const consentGiven = cookies.get('cookieConsent')
      if (consentGiven === undefined) {
        setTimeout(() => setIsVisible(true), 5000)
      }

      if (consentGiven === 'false') {
        // Do not track
        console.log('Disabling tracking due to user opt-out')
      }

      if (consentGiven === 'true') {
        startTracking()
      }
    },
    [
      /* Should only init tracking once */
    ],
  )

  if (!isVisible) {
    return null
  }

  return (
    <div
      className="fixed z-20 top-3/4 left-1/2 -translate-y-1/2 -translate-x-1/2 px-5 w-full pointer-events-none"
      id="cookie-consent-popup"
    >
      <UI.Block
        gap="small"
        className="rounded-xl shadow-xl w-full sm:max-w-lg bg-white border-2 border-selphBlue-600 p-2 sm:py-6 sm:px-6 mx-auto pointer-events-auto"
      >
        <UI.Heading size="small">Respecting your privacy</UI.Heading>
        <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>
          By accepting, you agree to the storing of cookies on your device, to remember your preferences, analyse usage
          of our site and aid our marketing efforts.
        </UI.Paragraph>
        <UI.Paragraph size="small">
          You can review our <UI.Link to="cookie">Cookie Policy</UI.Link> and{' '}
          <UI.Link to="privacy">Privacy Policy</UI.Link>.
        </UI.Paragraph>
        <div className="flex justify-between items-center gap-x-2">
          <UI.Button type="text" color="secondary" onClick={onReject} className="hover:underline">
            Reject
          </UI.Button>

          <UI.Button theme="solid" color="pink" onClick={onAccept} size={'small'}>
            Accept
          </UI.Button>
        </div>
      </UI.Block>
    </div>
  )
}

export default Tracking
