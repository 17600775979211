import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useMailchimp } from '../../user/use-mailchimp'
import useStorage from '../../use-storage'
import emailOfferPic from '../../../public/images/emailPopUpImg.png'
import * as UI from '@/ui'

type SignupFormValue = {
  email: string
}

export const EmailOfferBanner = () => {
  const { getItem, setItem } = useStorage()
  const [source, setSource] = useState('welcomeEmailOffer_banner')
  const [showModal, setShowModal] = useState(false)
  const { signUp, success, isReady } = useMailchimp()
  const handleSubmit = async (value: SignupFormValue) => {
    const res = await signUp({ email: value.email, source: source, tags: ['WELCOME10', 'newsletter'] })
    // send event to trigger mc browsing tagging on /tests/[slug]
    if (typeof window !== 'undefined' && res?.success) {
      window.dispatchEvent(new CustomEvent('newsletterSignup', { detail: value.email }))
    }
  }

  // show pop up 30 sec after mounted
  useEffect(() => {
    const timer = setTimeout(() => {
      // don't show modal if it has already been shown
      const modalHasShown = getItem('welcomeEmailOffer', 'session')
      if (!modalHasShown) {
        setShowModal(true)
        setItem('welcomeEmailOffer', true, 'session')
        TagManager.dataLayer({
          dataLayer: {
            event: 'showWelcomeEmailOffer',
          },
        })
        setSource('welcomeEmailOffer_popUp')
      }
    }, 30000)
    // clear timer if the component unmounts
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div
        className="bg-selphGrey-900 py-2 px-2 text-center h-full flex items-center justify-center"
        id="welcomeEmailOfferBanner"
      >
        <UI.Button type="text" onClick={() => setShowModal(true)}>
          <span className="text-hotPink-500 text-xl underline font-semibold">Get 10% off your first test!</span>
        </UI.Button>
      </div>
      <UI.Modal color="none" container={false} show={showModal} onClose={() => setShowModal(false)} disableOutsideClose>
        <div className="mx-2 max-w-lg md:mx-auto bg-gradient-to-b from-[#DDDEDE] to-[#F3F3F3]">
          <UI.Image src={emailOfferPic} alt="Email Offer" placeholder="blur" className="w-full " />
          {isReady && success ? (
            <UI.Block className="p-4 text-center">
              <p className="text-2xl text-hotPink-500 font-semibold">You&apos;re all set!</p>
              <p className="text-2xl text-selphBlue-500 ">Keep an eye on your inbox for your 10% off discount code.</p>
            </UI.Block>
          ) : (
            <>
              <UI.Form.Form<SignupFormValue>
                initialValues={{ email: '' }}
                validationSchema={UI.Form.validation.schema({
                  email: { label: 'Email Address', type: 'string', required: true, criteria: 'emailAddress' },
                })}
                onSubmit={handleSubmit}
              >
                <UI.Block className="p-4 text-center">
                  <UI.Heading
                    size={{ default: 'medium', sm: 'large', md: 'xl' }}
                    className="bg-gradient-to-r from-[#0F325E] via-[#336BB5] to-[#0F325E] inline-block text-transparent bg-clip-text"
                  >
                    Get 10% Off Your First Test
                  </UI.Heading>
                  <UI.Block gap="small">
                    <p className="text-2xl text-hotPink-500 font-semibold">
                      And clear answers to your health questions.
                    </p>
                    <p className="text-2xl text-selphBlue-500 ">Join thousands making informed health decisions.</p>
                  </UI.Block>
                  <div className="w-[90%] mx-auto">
                    <UI.Form.Text name="email" placeholder="Email address" />
                    {isReady && !success && (
                      <p className="text-red-500 text-sm mt-1">
                        Sorry, there was a problem signing you up. Please try again.
                      </p>
                    )}
                  </div>

                  <UI.Button type="submit" color="pink" size="full">
                    <span className="sm:py-1 md:py-2 text-2xl sm:text-3xl">Save 10% Now</span>
                  </UI.Button>

                  <UI.Paragraph size="small">
                    By signing up, you agree to receive Selph&apos;s emails on optimising your health. You can read our{' '}
                    <UI.Link to="privacy">Privacy Policy</UI.Link>, and of course you can unsubscribe at any time.
                  </UI.Paragraph>
                </UI.Block>
              </UI.Form.Form>
            </>
          )}
        </div>
      </UI.Modal>
    </>
  )
}

export default EmailOfferBanner
